import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import {ADVERTORIAL_BASE_URL} from '../constants'
import {formatAdvertorialGifs} from '../util/advertorialImages'
import Layout from '../components/layout'

import Advertorial from '../components/templates/sp-644-advertorial/advertorial'

const NewsSearch = () => {
    const images = useStaticQuery(graphql`
        query AdvertorialNewsSearch {
            hero: file(relativePath: {eq: "advertorial/news/news-hero.png"}) {
                childImageSharp {
                    fluid(maxWidth: 1000) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            allFile(filter: {base: {glob: "*news*"}, extension: {eq: "gif"}}) {
                edges {
                    node {
                        publicURL
                    }
                }
            }
        }
    `)

    const seo = {
        title: `Want Unprofiled News Not Based on Your Past Searches? | Startpage`,
        description: `Beware non-private search engines are saving and selling your news search history. Learn how to receive unprofiled news and search results with Startpage.`,
        ogImagePath: `${ADVERTORIAL_BASE_URL}${images.hero.childImageSharp.fluid.src}`,
        ogUrl: `${ADVERTORIAL_BASE_URL}/news-search/`,
    }

    // see https://www.gatsbyjs.org/packages/gatsby-source-filesystem/ for querying / working with gifs in gatsby
    const sectionImages = formatAdvertorialGifs(images)

    const section1Text1 = `
        <p>If you think you are seeing the same search results for News as everyone else, think again! Perhaps even more alarming, your search engine, advertisers, politicians, and the government are spying on the News you search for and websites you visit.</p>

        <p>This occurs because non-private search engine algorithms selectively determine what information a person sees in their search results. According to Digital Trends and GCF, consumers need to break out of the news filter bubble and stop their news feed from being controlled by Big Tech and media giants.*</p>

        <p>News should be un-biased and fact-based, but unfortunately, if you’re using a non-private search engine, you are receiving profiled, “personalized” news selected by an algorithm. During an election year this threat is even more concerning!</p>

        <p>So what should you do?</p>

        <p>Experts from all corners of the internet have been advising that people “start searching in private!”  Thankfully, the brilliant engineers from the Netherlands have found a solution with Startpage the world’s most private search engine.</p>
    `
    const section1Text2 = `
        <p>Take the target off your back, escape profiled news, and start searching in private! Startpage is the world’s most private search engine that delivers Google search results through a proprietary anonymizing process. No search tracking or profiling. And no search history, ever.</p>
    `
    const section2Text1 = `
        <p>You can also view entire websites using Startpage’s ‘Anonymous View’ feature without leaving a trace and without having your online behavior used for profiled news. Fast Company says, “This alternative search engine is basically Google without the privacy headaches.”</p>
    `
    const section3Text1 = `
        <p>Stop profiled news and search targeting. Start with Startpage!</p>

        <p>Do you know what the best part about Startpage is? It’s completely FREE! Try it out now.</p>
    `
    const bottomLinks = `
        <ul>
            <li>
                Digital Trends:
                <a href="https://www.digitaltrends.com/social-media/fake-news-and-filter-bubbles/">https://www.digitaltrends.com/social-media/fake-news-and-filter-bubbles/</a>
            </li>
            <li>
                Goodwill Community Foundation:
                <a href="https://edu.gcfglobal.org/en/digital-media-literacy/how-filter-bubbles-isolate-you/1/">https://edu.gcfglobal.org/en/digital-media-literacy/how-filter-bubbles-isolate-you/1/</a>
            </li>
        </ul>
    `

    const contentData = {
        sections: [
            {
                title: 'Why Privacy is Important When Searching for News Online',
                image: images.hero.childImageSharp.fluid,
            },
            {
                title: 'Non-Private Search Engines Are Selling Your Search History and Digital Profile',
                text1: section1Text1,
                text2: section1Text2,
            },
            {
                title: 'How Private Search works: No News Algorithms, No Targeting, No Search History',
                image: sectionImages[0],
                text1: section2Text1,
            },
            {
                title: 'Startpage’s Private Browsing Feature ‘Anonymous View’',
                image: sectionImages[1],
                text1: section3Text1,
            },
            {
                text1: bottomLinks,
            },
        ],
    }
    return (
        <Layout>
            <Advertorial content={contentData} seo={seo} />
        </Layout>
    )
}

export default NewsSearch
